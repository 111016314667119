import { useCallback, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import type UserCartStore from '../UserData/_stores/UserCartStore'
import { Button, Chip, Collapse, Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { SaveCartAddress } from '../UserData/_actions/UserCartActions'
import EditAddressDialog from '../Account/Settings/Addresses/EditAddressDialog'
import ItemList from '../Common/ItemList/ItemList'
import LabLoader from '../DesignLab/_components/LabLoader'
import { UserAddressStore } from '../UserData/_stores/UserAddressStore'
import { FetchOneItem } from '../UserData/_actions/UserDataActions'
import { CartStack } from '../Cart/CartContent/CartStack'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import AddressForm from '../Account/Settings/Addresses/AddressForm'

interface Props {
  cart: UserCartStore
  hasMultipleCarts?: boolean
  type: 'shipping' | 'billing'
  disabled?: boolean
}

export default function CheckoutAddressSelector(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const countries = useAppSelector(state => state.get('appData').get('countries'))
  const addresses = useAppSelector(state => state.get('userData').get('addresses').get('data'))
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  const [manualAddress, setManualAddress] = useState(new UserAddressStore())
  const [addressFormValid, setAddressFormValid] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  const [saveAddress, setSaveAddress] = useState(false)

  const [editDialogOpened, setEditDialogOpened] = useState(false)

  const cartAddressId = useMemo(() => {
    return props.cart.get(props.type === 'shipping' ? 'id_shipping_address' : 'id_billing_address')
  }, [props.cart, props.type])

  const cartAddress = useMemo(() => {
    return addresses.get(String(cartAddressId))
  }, [cartAddressId, addresses])

  const shippingAddressLine = useMemo(() => {
    return props.cart.get('address') +
      (props.cart.get('address2') !== '' ? ', ' + props.cart.get('address2') : '') +
      (props.cart.get('city') !== '' ? ', ' + props.cart.get('city') : '') +
      (props.cart.get('postalCode') !== '' ? ', ' + props.cart.get('postalCode') : '') +
      (props.cart.get('province') !== '' ? ', ' + props.cart.get('province') : '') +
      (props.cart.get('country') !== '' ? ', ' + props.cart.get('country') : '')
  }, [props.cart])

  const isSameAsShipping = useMemo(() => {
    return props.type === 'billing' &&
      cartAddressId == null &&
      !props.hasMultipleCarts &&
      props.cart.get('name') === props.cart.get('billing_name') &&
      props.cart.get('address') === props.cart.get('billing_address') &&
      props.cart.get('address2') === props.cart.get('billing_address2') &&
      props.cart.get('city') === props.cart.get('billing_city') &&
      props.cart.get('province') === props.cart.get('billing_province') &&
      props.cart.get('postalCode') === props.cart.get('billing_postalCode') &&
      props.cart.get('country') === props.cart.get('billing_country') &&
      props.cart.get('shipping_phone') === props.cart.get('billing_phone')
  }, [props.type, props.cart, cartAddressId, props.hasMultipleCarts])

  const [selectedAddressId, setSelectedAddressId] = useState<number | 'manual' | 'same_as_shipping'>(cartAddressId ?? (isSameAsShipping ? 'same_as_shipping' : 'manual'))

  useEffect(() => {
    if (cartAddressId != null) {
      dispatch(FetchOneItem(String(cartAddressId), 'addresses'))
      setSelectedAddressId(cartAddressId)

      // Reset manual address
      setManualAddress(new UserAddressStore())
    } else {
      // Fill in all inputs if we're on custom address
      if (props.type === 'shipping') {
        const country = countries.find(country => country.get('name') === props.cart.get('country'))

        setManualAddress(new UserAddressStore({
          name: props.cart.get('name'),
          address: props.cart.get('address'),
          address2: props.cart.get('address2'),
          city: props.cart.get('city'),
          province: props.cart.get('province'),
          postal_code: props.cart.get('postalCode'),
          country: country ? country.get('id') : 0,
          phone: props.cart.get('shipping_phone')
        }))

        // Show form if we're missing fields
        if (!country) {
          setIsEditing(true)
        }

        setSelectedAddressId('manual')
      } else {
        if (isSameAsShipping) {
          setSelectedAddressId('same_as_shipping')

          // Reset manual address
          setManualAddress(new UserAddressStore())
        } else {
          const country = countries.find(country => country.get('name') === props.cart.get('billing_country'))

          setManualAddress(new UserAddressStore({
            name: props.cart.get('billing_name'),
            address: props.cart.get('billing_address'),
            address2: props.cart.get('billing_address2'),
            city: props.cart.get('billing_city'),
            province: props.cart.get('billing_province'),
            postal_code: props.cart.get('billing_postalCode'),
            country: country ? country.get('id') : 0,
            phone: props.cart.get('billing_phone')
          }))

          // Show form if we're missing fields
          if (!country) {
            setIsEditing(true)
          }
          setSelectedAddressId('manual')
        }
      }
    }
  }, [props.cart, countries, cartAddressId, isSameAsShipping])

  const onSelectAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(false)
    setSelectedAddressId(Number(e.target.value))

    const fd = new FormData()
    fd.append('cart_id', String(props.cart.get('id')))
    fd.append('address_id', e.target.value)
    fd.append('type', props.type)

    dispatch(SaveCartAddress(fd))
  }, [props.cart, props.type])

  const onSubmitManualAddress = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const fd = new FormData()
    fd.append('cart_id', String(props.cart.get('id')))

    fd.append('name', manualAddress.get('name'))
    fd.append('address', manualAddress.get('address'))
    fd.append('address2', manualAddress.get('address2'))
    fd.append('city', manualAddress.get('city'))
    fd.append('province', manualAddress.get('province'))
    fd.append('postal_code', manualAddress.get('postal_code'))
    fd.append('country', String(manualAddress.get('country')))
    fd.append('phone', manualAddress.get('phone'))

    if (saveAddress) {
      fd.append('save_address', '1')
      fd.append('default_shipping', manualAddress.get('default_shipping') ? '1' : '0')
      fd.append('default_billing', manualAddress.get('default_billing') ? '1' : '0')
    }

    // Add update type
    fd.append('type', props.type)

    dispatch(SaveCartAddress(fd).set({
      onSuccess: () => {
        setIsEditing(false)

        // Reset fields if we just saved an address
        if (saveAddress) {
          setSaveAddress(false)
          setManualAddress(new UserAddressStore())
        }
      }
    }))
  }, [props.cart, manualAddress, saveAddress])

  const onSelectSameAsShipping = useCallback(() => {
    setIsEditing(false)
    const countryObj = countries.find(country => country.get('name') === props.cart.get('country'))

    const fd = new FormData()
    fd.append('cart_id', String(props.cart.get('id')))
    fd.append('type', props.type)

    fd.append('name', props.cart.get('name'))
    fd.append('address', props.cart.get('address'))
    fd.append('address2', props.cart.get('address2'))
    fd.append('city', props.cart.get('city'))
    fd.append('province', props.cart.get('province'))
    fd.append('postal_code', props.cart.get('postalCode'))
    fd.append('country', String(countryObj?.get('id')))
    fd.append('phone', props.cart.get('shipping_phone'))

    dispatch(SaveCartAddress(fd))
  }, [props.cart])

  const onEditDialogOpen = useCallback(() => {
    setEditDialogOpened(true)
  }, [])

  const onEditDialogClose = useCallback(() => {
    setEditDialogOpened(false)
  }, [])

  const manualAddressLine = useMemo(() => {
    const countryObj = countries.get(String(manualAddress.get('country')))
    return [
      manualAddress.get('address'),
      manualAddress.get('address2'),
      manualAddress.get('city'),
      manualAddress.get('postal_code'),
      manualAddress.get('province'),
      countryObj?.get('name')
    ].filter(value => value !== '').join(', ')
  }, [manualAddress, countries])

  const renderAddress = useCallback((item: UserAddressStore) => {
    const address = item
    const countryObj = countries.get(String(address.get('country')))
    const addressLine = address.get('address') +
      (address.get('address2') !== '' ? ', ' + address.get('address2') : '') +
      (address.get('city') !== '' ? ', ' + address.get('city') : '') +
      (address.get('postal_code') !== '' ? ', ' + address.get('postal_code') : '') +
      (address.get('province') !== '' ? ', ' + address.get('province') : '') +
      (countryObj ? ', ' + countryObj.get('name') : '')

    const isDefault = props.type === 'shipping'
      ? address.get('default_shipping')
      : address.get('default_billing')

    return <Grid item xs={12} sm={6}>
      <FormControlLabel
        key={address.get('id')}
        value={address.get('id')}
        label={<div>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography
              variant="body1"
              component="p"
            >{address.get('name')}</Typography>

            {isDefault ? <Chip
              variant="square-filled"
              size="small"
              color="accent"
              label={t('Default')}
            /> : null}

            {selectedAddressId === address.get('id') ? <Button
              variant="contained"
              size="tiny"
              color="gray"
              startIcon={<EditIcon />}
              onClick={onEditDialogOpen}
            >{t('Edit')}</Button> : null}
          </Stack>
          <Typography variant="caption" component="p">{addressLine}</Typography>
        </div>}
        control={<Radio />}
        disabled={!!cartLoader || props.disabled}
      />
    </Grid>
  }, [countries, selectedAddressId, cartLoader, props.disabled])

  const renderListAddresses = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean, page: number) => {
    const displaySelectedAddress = selectedAddressId !== 'manual' && cartAddress && page === 1 ? renderAddress(cartAddress) : null;
    
    return <>
      {loading ? <>
        <Divider />
        <CartStack>
          <LabLoader />
        </CartStack>
      </> : (items || displaySelectedAddress ? <>
        <Divider />
        <CartStack>
          <RadioGroup
            name="address"
            value={selectedAddressId}
            onChange={onSelectAddress}
            style={{
              gap: '16px'
            }}
          >
            <Grid container spacing={2}>
              {displaySelectedAddress}
              {items}
            </Grid>
          </RadioGroup>
        </CartStack>
      </> : <></>
      )}

      {links}
    </>
  }, [selectedAddressId, onSelectAddress, cartAddress, renderAddress])

  const onChangeManualAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) return

    // Trigger edit automatically if there's no address
    if (!manualAddressLine) {
      setIsEditing(true)
    } else {
      onSubmitManualAddress()
    }
    setSelectedAddressId('manual')
  }, [manualAddressLine])

  const onClickEditManualAddress = useCallback(() => {
    setIsEditing(true)
    setSelectedAddressId('manual')
  }, [])

  return <div>
    <CartStack spacing={2}>
      <Typography variant="h3" color="primary">{props.type === 'shipping' ? t('Shipping address') : t('Billing address')}</Typography>

      <div>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value="manual"
              label={<div>
                <Typography variant="body1" component="p">{t('Enter address manually')}</Typography>
                {!isEditing && manualAddressLine && !isSameAsShipping ? <div style={{ padding: '8px' }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body1" component="p">{manualAddress.get('name')}</Typography>
                    <Button
                      size="tiny"
                      variant="contained"
                      color="gray"
                      startIcon={<EditIcon />}
                      disabled={!!cartLoader || props.disabled}
                      onClick={onClickEditManualAddress}
                    >{t('Edit address')}</Button>
                  </Stack>
                  <Typography variant="caption" component="p">{manualAddressLine}</Typography>
                </div> : null}
              </div>}
              control={<Radio
                name="address"
                checked={selectedAddressId === 'manual'}
                onChange={onChangeManualAddress}
              />}
              disabled={!!cartLoader || props.disabled}
              style={{
                alignItems: 'flex-start'
              }}
            />
          </Grid>
          {props.type === 'billing' && props.cart.get('id_shipping_address') == null && !props.hasMultipleCarts && props.cart.get('order_from') !== 'invoice' ? <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={'same_as_shipping'}
              label={<div>
                <Typography variant="body1" component="p">{t('Same as shipping')}</Typography>
                {shippingAddressLine ? <div style={{ padding: '8px' }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body1" component="p">{props.cart.get('name')}</Typography>
                  </Stack>
                  <Typography variant="caption" component="p">{shippingAddressLine}</Typography>
                </div> : null}
              </div>}
              control={<Radio
                name="address"
                checked={selectedAddressId === 'same_as_shipping'}
                onChange={props.cart.get('id_shipping_address') != null ? onSelectAddress : onSelectSameAsShipping}
              />}
              disabled={!!cartLoader || props.disabled}
              style={{
                alignItems: 'flex-start'
              }}
            />
          </Grid> : null}
          <Grid item xs={12}>
            <Collapse in={isEditing} sx={{ pt: 2 }}>
              <form onSubmit={onSubmitManualAddress}>
                <AddressForm
                  address={manualAddress}
                  onChange={isEditing ? setManualAddress : undefined}
                  onValidCheck={setAddressFormValid}
                  save={loggedIn ? saveAddress : false}
                  onChangeSave={loggedIn ? setSaveAddress : undefined}
                  disabled={!!cartLoader || props.disabled}
                />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!addressFormValid || !!cartLoader || props.disabled}
                  >{t('Confirm address')}</Button>
                </div>
              </form>
            </Collapse>
          </Grid>
        </Grid>
      </div>
    </CartStack>

    <ItemList
      dataType="addresses"
      controlled={true}
      item={renderAddress}
      excludeId={selectedAddressId !== 'manual' ? String(selectedAddressId) : undefined}
      limit={6}
      render={renderListAddresses}
    />

    {selectedAddressId !== 'manual' && addresses.has(String(selectedAddressId)) ? <EditAddressDialog
      address={addresses.get(String(selectedAddressId))}
      opened={editDialogOpened}
      onClose={onEditDialogClose}
    /> : null}
  </div>
}
