import * as Immutable from 'immutable';
import ProductOptionTypeStore from '../AppData/_stores/ProductOptionTypeStore';
import ProductOptionStore from '../AppData/_stores/ProductOptionStore';
import ProductStore from '../AppData/_stores/ProductStore';
import VariantPriceStore from '../Dashboard/_stores/VariantPriceStore';
import PriceStore from '../UIData/_stores/PriceStore';

export default class VariantPriceHelper {
	private product:ProductStore;
	private availableOptionTypes:Immutable.OrderedMap<string,ProductOptionTypeStore>

	constructor(product:ProductStore, availableOptionTypes:Immutable.OrderedMap<string,ProductOptionTypeStore>) {
		this.product = product;
		this.availableOptionTypes = availableOptionTypes.filter(type => {
			//Skip branding options
			return !['include-label'].includes(type.get('slug'))
		});
	}

	public buildVariantPrices = () => {
		let optionTypesWithNoEmptyPrice = [];
		let optionTypesWithOnlyOneEmptyPrice:number[] = [];
		let variantsWithNoPrices:number[] = [];

		this.availableOptionTypes.forEach((type) => {
			let hasEmptyPrice = false;
			let hasPrices = new Set();

			let optionsByPVariants = new Map<number, Map<string, ProductOptionStore>>();

			type.get('options').forEach((option) => {
				if(option.get('price_dropship').get('amount') > 0) {
					hasPrices.add(option.get('slug'));
				} else {
					hasEmptyPrice = true;
				}

				if(!optionsByPVariants.has(option.get('id_product_variant'))) {
					optionsByPVariants.set(option.get('id_product_variant'), new Map<string, ProductOptionStore>());
				}

				optionsByPVariants.get(option.get('id_product_variant')).set(option.get('slug'), option);
			});

			//Compare options by variants
			optionsByPVariants.forEach((listOptions, pVariantId) => {
				let hasSamePrice = true;
				listOptions.forEach((option) => {
					optionsByPVariants.forEach((listOptions2) => {
						if(listOptions2.has(option.get('slug')) 
							&& listOptions2.get(option.get('slug')).get('price_dropship').get('amount') != option.get('price_dropship').get('amount')) {
							hasSamePrice = false;
						}
					});
				});

				if(hasSamePrice) {
					variantsWithNoPrices.push(pVariantId);
				}
			});

			if(hasPrices.size === type.get('options').count()) {
				optionTypesWithNoEmptyPrice.push(type.get('id'));
			}

			if(type.get('options').count() > 1 && hasPrices.size === type.get('options').map((option) => option.get('slug')).toSet().size - 1) {
				optionTypesWithOnlyOneEmptyPrice.push(type.get('id'));
			}
		});

		let variant_prices = new Map<string, VariantPriceStore>();
		this.processVariantPrices([], this.availableOptionTypes, variant_prices, optionTypesWithOnlyOneEmptyPrice, variantsWithNoPrices);
		return variant_prices;
	}

	private processVariantPrices = (
		options:ProductOptionStore[], 
		remainingOptionTypes:Immutable.Map<string,ProductOptionTypeStore>, 
		variant_prices:Map<string, VariantPriceStore>,
		optionTypesWithOnlyOneEmptyPrice:number[],
		variantsWithNoPrices:number[],
	) => {
		//If we're at the last option type
		if(remainingOptionTypes.count() === 0) {
			let option_ids = options.map((option) => option.get('id'));
			let id = option_ids.sort().join('|');
			let basePrice = this.product.get('price_retail').toDinero();
			let price = basePrice;
			let costPrice = this.product.get('price_dropship').toDinero();
			let name = "";
			let slug = "";

			let i = 0;
			let hasOptionTypeWithOneEmptyPrice = false;
			let dependencyMatch = true;

			//Create a list of options that are used as dependencies
			let listDependencies = new Set();
			options.forEach((option) => {
				option.get('listDependencies').forEach((dependencyId) => listDependencies.add(dependencyId));
			});

			options.forEach((option) => {
				//Check dependencies
				if(option.get('listDependencies').count() > 0) {
					dependencyMatch = false;
					option.get('listDependencies').forEach((dependencyId) => {
						dependencyMatch = dependencyMatch || option_ids.indexOf(dependencyId) !== -1
					});
				}

				hasOptionTypeWithOneEmptyPrice = hasOptionTypeWithOneEmptyPrice || optionTypesWithOnlyOneEmptyPrice.indexOf(option.get('id_product_option_type')) !== -1;

				//Skip options worth 0$
				if(optionTypesWithOnlyOneEmptyPrice.indexOf(option.get('id_product_option_type')) === -1 
					&& option.get('price_retail').get('amount') === 0
					&& !listDependencies.has(option.get('id'))
					&& variantsWithNoPrices.indexOf(option.get('id_product_variant')) !== -1
				) {
					return;
				}

				name += (i > 0 ? ' / ':'') + option.get('name');

				//Slugs are based on a combination of the option slug and listDependencies, so we can eliminate duplicates,
				//while keeping similar options that have different dependencies
				slug += (i > 0 ? '|':'') 
					+ option.get('slug')
					+'$'+option.get('listDependencies').join('_')
					+(option.get('id_product_variant') > 0 
						&& variantsWithNoPrices.indexOf(option.get('id_product_variant')) === -1 ? '$'+option.get('id_product_variant') : '');
				price = price.add(option.get('price_retail').toDinero());
				costPrice = costPrice.add(option.get('price_dropship').toDinero());
				i++;
			});

			//Skip if there's no dependency match
			if(!dependencyMatch) {
				return;
			}

			//Ignore this variantPrice if no options were kept
			if(i === 0 && !hasOptionTypeWithOneEmptyPrice) {
				return;
			}

			variant_prices.set(id, new VariantPriceStore({
				name: name,
				slug: slug,
				price: PriceStore.fromDinero(price),
				costPrice: PriceStore.fromDinero(costPrice),
				option_ids: option_ids,
				options: options,
			}));

			return;
		}

		let type = remainingOptionTypes.first();

		type.get('options').entrySeq().forEach(([optionId, option]) => {
			this.processVariantPrices(
				[...options, option], 
				remainingOptionTypes.filter((fType) => type.get('id') !== fType.get('id')), 
				variant_prices, 
				optionTypesWithOnlyOneEmptyPrice,
				variantsWithNoPrices
			);
		});
	}
}