import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide } from "three"

export default function ScoopBralette(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/scoop-bralette.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Move the model up - the stage follows on any reload so that's a bit silly but it works
    // TODO: Should get the drei stage somehow and move it
    useEffect(() => {
        for(let e in elements) {
            elements[e].position.y += 0.06
        }
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        
        let product_mat = FindMaterial('Peachskin_outside', elements)
        if(product_mat){
            product_mat.map = productTexture
            product_mat.map.flipY = false
            product_mat.map.needsUpdate = true
            product_mat.side = DoubleSide
        }
        
        let elastic_mat = FindMaterial('Peachskin_details', elements)
        if(elastic_mat){
            elastic_mat.map = productTexture
            elastic_mat.map.flipY = false
            elastic_mat.map.needsUpdate = true
        }
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}