import { MeshStandardMaterial, Object3D, Object3DEventMap } from "three";
import { HasStandardMaterial } from "../../ModelViewer/_hooks/useGLB";

const _simplify_string = (to_format: string): string => to_format.replace(/[^a-zA-Z]+/g, '').toLowerCase()

// This assumes a flat object and should be modified to handle groups, but for now the models themselves are flattened instead.

export function FindMaterial(material_name: string, elements: Object3D<Object3DEventMap>[] & HasStandardMaterial[]): MeshStandardMaterial | null {
    var search_name = _simplify_string(material_name)
    for (var element in elements) {
        if(!elements[element].hasOwnProperty("material")) continue
        var name = _simplify_string(elements[element].material.name)
        if(name == search_name) return elements[element].material
    }
    return null
}

export function FindObject(object_name: string, elements: Object3D<Object3DEventMap>[] & HasStandardMaterial[]): Object3D & HasStandardMaterial | null {
    var search_name = _simplify_string(object_name)
    for (var element in elements) {
        var name = _simplify_string(elements[element].name)
        if(name == search_name) return elements[element]
    }
    return null
}