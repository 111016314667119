import * as Immutable from 'immutable';
import PriceStore from '../../UIData/_stores/PriceStore';
import ProductOptionStore from '../../AppData/_stores/ProductOptionStore';

export interface IVariantPriceStore {
	name: string,
	slug: string,
	price: PriceStore,
	costPrice: PriceStore,
	option_ids: number[],
	options: ProductOptionStore[],
}

const defaultVariantPriceStore = Immutable.Record<IVariantPriceStore>({
	name: "",
	slug: "",
	price: new PriceStore(),
	costPrice: new PriceStore(),
	option_ids: [],
	options: [],
});

export default class VariantPriceStore extends defaultVariantPriceStore implements IVariantPriceStore {}