import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import Dinero from 'dinero.js';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductOptionStore from '../../AppData/_stores/ProductOptionStore';
import Immutable from 'immutable';
import SectionPricingOption from './SectionPricingOption';
import { Box, Button, Container, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import classNames from 'classnames';
import tKey from '../../Common/Utils/tKey';
import VariantPriceHelper from '../../MassProductSending/VariantPriceHelper';

export const PricingType = createContext<'customer'|'reseller'>('customer')

const StyledTable = styled(Table)(({ theme }) => ({
  width: 'auto',
  margin: 'auto',

  '&.type-reseller': {
    minWidth: '600px',
  },

  [theme.breakpoints.up('md')]: {
    minWidth: '600px',
  }
}))

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  border: 0,
}))

const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#cbecf8',
  textTransform: 'none',
  fontSize: '1.05rem',
  color: theme.palette.secondary.main,
  borderRadius: 0,
  borderLeft: '1px solid transparent',
  borderRight: '1px solid transparent',
  borderBottom: 0,
  padding: theme.spacing(1, 2),

  '&.level1': {
    backgroundColor: '#addff2',
  },

  '&.level2': {
    backgroundColor: '#99cee2',
  }
}))

const PricingTypeButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,

  '&.active': {
    zIndex: 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&.left': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(6),
    marginRight: theme.spacing(-4),

    '&.active': {
      paddingRight: theme.spacing(3),
      marginRight: theme.spacing(0),
    }
  },

  '&.right': {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    marginLeft: theme.spacing(-4),

    '&.active': {
      paddingLeft: theme.spacing(3),
      marginLeft: theme.spacing(0),
    }
  },
}))

type Props = PropsWithChildren<{
  productId: number
  variants?: string[]
  options?: string[]
  listOptions?: boolean
  optionHeader?: string
  baseOptionName?: string
}>

export default function SectionPricing(props: Props) {
  const [t] = useTranslation('page/products/_sections');

  const currency = useAppSelector(state => state.get('userData').get('prefs').get('currency'))
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(props.productId)));

  const [pricingType, setPricingType] = useState<'customer' | 'reseller'>('customer')

  const variants = useMemo(() => {
    return productData?.get('variants').filter(variant => props.variants?.includes(variant.get('slug')))
  }, [productData])

  const priceOptions = useMemo(() => {
    let tmpPriceOptions: Immutable.Set<ProductOptionStore> = Immutable.Set<ProductOptionStore>()
    if (!props.options || props.options.length === 0) return tmpPriceOptions

    productData?.get('availableOptionTypes').forEach(type => {
      type.get('options').filter(option => {
        if (variants !== undefined && variants.count() > 0 && option.get('id_product_variant') > 0) {
          return variants.has(String(option.get('id_product_variant')))
        } else {
          return true
        }
      }).forEach(option => {
        if (!props.options?.includes(option.get('slug'))) {
          return
        }

        tmpPriceOptions = tmpPriceOptions.add(option)
      })
    })

    return tmpPriceOptions
  }, [productData, variants, props.options])

  const listCartesianOptions = useMemo(() => {
    if(!props.listOptions || !productData) return null;

    //Filter out options that don't match the variants
    let availableOptionTypes = productData.get('availableOptionTypes');
    productData.get('availableOptionTypes').forEach((type, typeKey) => {
      let newType = type;

      type.get('options').forEach((option, optionKey) => {
        if (variants !== undefined && variants.count() > 0 && option.get('id_product_variant') > 0 && !variants.has(String(option.get('id_product_variant')))) {
          newType = newType.set('options', newType.get('options').remove(optionKey));
        }
      })

      availableOptionTypes = availableOptionTypes.set(typeKey, newType);
    });

    //Create cartesian options using variant price helper
    let helper = new VariantPriceHelper(productData, availableOptionTypes);
		let defaultVariantPrices = helper.buildVariantPrices();

    //Sort all options
    let tmpCartesianOptions = Immutable.Map(defaultVariantPrices);
    tmpCartesianOptions = tmpCartesianOptions.sort((a, b) => {
      let sortValue = 0;
      a.get('options').forEach((option, index) => {
        if(sortValue !== 0) return;

        const bOption = b.get('options')[index];

        if(!bOption) {
          sortValue = 1;
          return;
        }

        if(option.get('theorder') > bOption.get('theorder')) {
          sortValue = 1;
        } else if(option.get('theorder') < bOption.get('theorder')) {
          sortValue = -1;
        } else {
          sortValue = option.get('id') - bOption.get('id');
        }
      });

      return sortValue;
    })

    return tmpCartesianOptions;
  }, [productData, props.listOptions, variants])

  const [priceDropship, priceRetail, priceWholesale] = useMemo(() => {
    if (!productData) {
      return [
        Dinero({ amount: 0, currency: currency }),
        Dinero({ amount: 0, currency: currency }),
        Dinero({ amount: 0, currency: currency }),
      ]
    }

    //Base product prices
    let tmpPriceDropship = productData.get('price_dropship').toDinero(),
      tmpPriceRetail = productData.get('price_retail').toDinero(),
      tmpPriceWholesale = productData.get('price_wholesale').toDinero();

    return [
      tmpPriceDropship,
      tmpPriceRetail,
      tmpPriceWholesale,
    ];
  }, [productData, currency]);

  const onClickCustomer = useCallback(() => {
    setPricingType('customer')
  }, [])

  const onClickReseller = useCallback(() => {
    setPricingType('reseller')
  }, [])

  return <Box component="div" sx={{
    py: {
      xs: 8,
      md: 16,
    },
    backgroundColor: '#fff',
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
  }}>
    <Container
      maxWidth="lg"
    >
      <Stack spacing={8} alignItems="center">
        <Container maxWidth="md">
          <Stack spacing={2} alignItems="center" textAlign="center">
            <Stack spacing={-4}>
              <Typography
                variant="h1-accent"
                component="h3"
              >{t('Pricing')}</Typography>
              <Typography
                variant="h1"
                component="h3"
                color="secondary.main"
              >{t('Find your pricing level')}</Typography>
            </Stack>

            <Typography variant="body1">{t('Whether you\'re buying something for yourself, or you\'re interested in selling your own products, our prices remain the same. Use the table below to calculate your potential profit when drop shipping or buying wholesale.')}</Typography>
          </Stack>
        </Container>

        <Stack
          direction="row"
          sx={{
            backgroundColor: '#ebebeb',
            borderRadius: '50%',
          }}
        >
          <PricingTypeButton
            variant="contained"
            color={pricingType === 'customer' ? 'primary' : 'gray'}
            size="large"
            onClick={onClickCustomer}
            disabled={pricingType === 'customer'}
            className={classNames({
              left: true,
              active: pricingType === 'customer'
            })}
          >{t('I\'m buying for myself')}</PricingTypeButton>
          <PricingTypeButton
            variant="contained"
            color={pricingType === 'reseller' ? 'primary' : 'gray'}
            size="large"
            onClick={onClickReseller}
            disabled={pricingType === 'reseller'}
            className={classNames({
              right: true,
              active: pricingType === 'reseller'
            })}
          >{t('I\'m selling products')}</PricingTypeButton>
        </Stack>

        <TableContainer>
          <StyledTable className={'type-' + pricingType}>
            <StyledTableHead>
              <TableRow>
                {props.optionHeader ? <HeaderCell component="th" align="left">{props.optionHeader}</HeaderCell>
                  : props.listOptions ? <HeaderCell component="th" align="left">{productData?.get('availableOptionTypes').valueSeq().map(type => type.get('name')).join(' / ')}</HeaderCell> : null}
                {pricingType === 'reseller' ? <HeaderCell component="th" align="center">{t('Your Price')}</HeaderCell> : null}
                <HeaderCell component="th" align="center" className="level1">
                  {pricingType === 'reseller'
                    ? <Trans
                      t={t}
                      i18nKey={tKey('Cost for<br/>1-5 items')}
                      components={{
                        br: <br />
                      }}
                    />
                    : <Trans
                      t={t}
                      i18nKey={tKey('Price for<br/>1-5 items')}
                      components={{
                        br: <br />
                      }}
                    />
                  }
                </HeaderCell>
                {pricingType === 'reseller' ? <HeaderCell component="th" align="center" className="level1">{t('Your Profit')}</HeaderCell> : null}
                <HeaderCell component="th" align="center" className="level2">
                  {pricingType === 'reseller'
                    ? <Trans
                      t={t}
                      i18nKey={tKey('Cost for<br/>6+ items')}
                      components={{
                        br: <br />
                      }}
                    />
                    : <Trans
                      t={t}
                      i18nKey={tKey('Price for<br/>6+ items')}
                      components={{
                        br: <br />
                      }}
                    />
                  }
                </HeaderCell>
                {pricingType === 'reseller' ? <HeaderCell component="th" align="center" className="level2">{t('Your Profit')}</HeaderCell> : null}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              <PricingType.Provider value={pricingType}>
                {props.listOptions ? <>
                  { listCartesianOptions ? listCartesianOptions.valueSeq().map(cartesianOption => {
                      return <SectionPricingOption
                        key={cartesianOption.get('slug')}
                        basePriceDropship={priceDropship}
                        basePriceWholesale={priceWholesale}
                        basePriceRetail={priceRetail}
                        options={Immutable.Set(cartesianOption.get('options'))}
                        showOptionNames
                      />
                  }) : null }
                </> : <SectionPricingOption
                  basePriceDropship={priceDropship}
                  basePriceWholesale={priceWholesale}
                  basePriceRetail={priceRetail}
                  options={priceOptions}
                  showOptionNames={!!props.optionHeader}
                  name={props.baseOptionName}
                />}

                { props.children }
              </PricingType.Provider>
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Stack>
    </Container>
  </Box>
}