import {
  CSSProperties,
  Ref,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import { useSelector, Provider, ReactReduxContext, ReactReduxContextValue, useStore } from "react-redux";

import { Stage } from 'konva/lib/Stage';
import WebsiteStore from "../../WebsiteStore";

import SceneRenderer from "./SceneRenderer";
import MirrorRenderer from "./MirrorRenderer";
import { Store } from "redux";
import RenderErrorBoundary from "./Renderbot/RenderErrorBoundary";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

type Props = {
  labData?: Record<string, any>,
  subproductRef?: string,
  subproductRef_labData?: Record<string, any>,
  inputType: 'lab',
  outputType: 'texture' | 'render',
  style?: CSSProperties,
  width?: number,
  height?: number,
  scaleX?: number,
  scaleY?: number,
  onError?: (error: any) => void
}

type RendererHandle = {
  getStage: () => Stage | null,
  getCanvas: () => HTMLCanvasElement | null,
}

function Renderer(props: Props, ref: Ref<RendererHandle>) {
  const variant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const subproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));

  const stageRef = useRef<Stage>(null);

  useImperativeHandle(ref, () => {
    return {
      getStage: () => {
        return stageRef.current;
      },
      getCanvas: () => {
        return stageRef.current ? stageRef.current.toCanvas() : null
      }
    }
  }, []);


  const renderScene = useCallback((scene:string) => {
    if (!props.labData || !props.labData.variants[variant]) return null

    return <SceneRenderer
      key={scene}
      subproduct={subproduct}
      subproductRef={props.subproductRef}
      scene={scene}
      sceneData={props.labData.variants[variant].scenes[scene]}
      subproductRef_sceneData={props.subproductRef_labData?.variants[variant].scenes[scene]}
      inputType={props.inputType}
      outputType={props.outputType}
      labData={props.labData}
    />
  }, [props.labData,variant,subproduct,props.subproductRef,props.subproductRef_labData,props.inputType,props.outputType])

  const renderMirror = useCallback((scene:string) => {
    if (!props.labData || !props.labData.variants[variant]) return null

    return <MirrorRenderer
      key={scene}
      subproduct={subproduct}
      subproductRef={props.subproductRef}
      scene={scene}
      sceneData={props.labData.variants[variant].scenes[scene]}
      labData={props.labData}
      subproductRef_labData={props.subproductRef_labData}
      inputType={props.inputType}
      outputType={props.outputType}
    />
  }, [props.labData,variant,subproduct,props.subproductRef,props.subproductRef_labData,props.inputType,props.outputType])

  if (!props.labData || !props.labData.variants[variant]) return null;

  const finalWidth = props.width || props.labData.variants[variant].dimensions[props.outputType].width;
  const finalHeight = props.height || props.labData.variants[variant].dimensions[props.outputType].height;

  const scenes = Object.keys(props.labData.variants[variant].scenes);

  return <StageNode
    ref={stageRef}
    width={finalWidth}
    height={finalHeight}
    scaleX={props.scaleX}
    scaleY={props.scaleY}
    listening={false}
    style={{
      display: 'none',
      ...props.style
    }}
  >
    <RenderErrorBoundary onError={props.onError}>
      { /* Scenes */}
      {scenes.map(renderScene)}

      { /* Mirrors */}
      {scenes.map(renderMirror)}
    </RenderErrorBoundary>
  </StageNode>
}

export default forwardRef(Renderer);