import * as Immutable from 'immutable';

import { AjaxActionEnd, AjaxActionStart } from '../../Common/_actions/AjaxAction';
import { AppDataAction, AppDataActionType } from '../_actions/AppDataActions';
import AppDataStore, { Country } from '../_stores/AppDataStore';
import PreviewStore from '../_stores/PreviewStore';
import ProductCategoryStore, { IProductCategoryStore } from '../_stores/ProductCategoryStore';
import ProductOptionStore, { IProductOptionStore } from '../_stores/ProductOptionStore';
import ProductOptionTypeStore from '../_stores/ProductOptionTypeStore';
import ProductStore, { IProductStore } from '../_stores/ProductStore';
import ProductSubCategoryStore, { IProductSubCategoryStore } from '../_stores/ProductSubCategoryStore';
import ProductVariantStore from '../_stores/ProductVariantStore';
import ViewCategoryStore, { IViewCategoryStore } from '../_stores/ViewCategoryStore';
import ViewProductAttributeValueStore, { IViewProductAttributeValueStore } from '../_stores/ViewProductAttributeValueStore';
import ViewProductStore from '../_stores/ViewProductStore';
import ProductDetailStore from '../_stores/ProductDetailStore';
import ProductDetailValueStore, { IProductDetailValueStore } from '../_stores/ProductDetailValueStore';
import PriceStore from '../../UIData/_stores/PriceStore';
import ShippingTypeStore, { ShippingTypePayload } from '../_stores/ShippingTypeStore';
import StoreIntegrationStore, { StoreIntegrationPayload } from '../_stores/StoreIntegration';
import ProductSubproductStore from '../_stores/ProductSubproductStore';

const AppDataReducer = (
	store:AppDataStore = new AppDataStore(),
	action: AppDataAction 
		| AjaxActionStart<unknown>
		| AjaxActionEnd<unknown>
):AppDataStore => {
	switch (action.type) {
		case AppDataActionType.EditAppData:
			return store.withMutations(store => {
				//Add countries with provinces
				for (const [key, value] of Object.entries(action.data.data.countries)) {
					let country = Immutable.fromJS(value) as Country;

					//Sort provinces
					if(country.get('provinces').count() > 0) {
						country = country.set('provinces', country.get('provinces').sort((a, b) => a.get('name').localeCompare(b.get('name'))));
					}

					store.setIn(['countries', String(key)], country);
				}

				//Sort countries
				store.set('countries', store.get('countries').sort((a, b) => a.get('name').localeCompare(b.get('name'))));

				//Add product categories
				for (const [key, value] of Object.entries(action.data.data.productCategories)) {
					let {...rest} = value as IProductCategoryStore;
					let category = new ProductCategoryStore();
					category = category.withMutations(category => {
						category.merge(Immutable.fromJS(rest));
					});

					store.setIn(['productCategories', String(category.get('id'))], category);
				}

				//Sort product categories
				store.set('productCategories', store.get('productCategories').sort((a, b) => a.get('theorder') - b.get('theorder')));

				//Add product subcategories
				for (const [key, value] of Object.entries(action.data.data.productSubCategories)) {
					let {...rest} = value as IProductSubCategoryStore;
					let category = new ProductSubCategoryStore();
					category = category.withMutations(category => {
						category.merge(Immutable.fromJS(rest));
					});

					store.setIn(['productSubCategories', String(category.get('id'))], category);
				}

				//Sort product categories
				store.set('productSubCategories', store.get('productSubCategories').sort((a, b) => a.get('theorder') - b.get('theorder')));

				//Add products
				for (const [key, value] of Object.entries(action.data.data.products)) {
					let {price_retail, price_wholesale, price_dropship, availableOptionTypes, variants, subproducts, previews, details, ...rest} = value as IProductStore;
					let product = new ProductStore();
					product = product.withMutations(product => {
						product.merge(Immutable.fromJS(rest));
						//product.set('viewerVersion', viewerVersion.version)
						product.set('price_retail', new PriceStore(price_retail));
						product.set('price_wholesale', new PriceStore(price_wholesale));
						product.set('price_dropship', new PriceStore(price_dropship));

						//Process option types
						let listTypes = Immutable.OrderedMap<string, ProductOptionTypeStore>();
						for (const [key, typeData] of Object.entries(availableOptionTypes)) {
							let type = new ProductOptionTypeStore({
								id: typeData.id,
								type: typeData.type,
								slug: typeData.slug,
								name: typeData.name,
								theorder: typeData.theorder,
								visible: typeData.visible,
							});

							Object.values(typeData.options).forEach((optionData:any) => {
								let {price_retail, price_wholesale, price_dropship, ...rest} = optionData as IProductOptionStore;
								let option = new ProductOptionStore();
								option = option.withMutations(option => {
									option.merge(Immutable.fromJS(rest));
									option.set('price_retail', new PriceStore(price_retail));
									option.set('price_wholesale', new PriceStore(price_wholesale));
									option.set('price_dropship', new PriceStore(price_dropship));
								});

								type = type.setIn(['options',String(optionData.id)], option);
							});

							type = type.set('options', type.get('options').sort((a, b) => a.get('theorder') - b.get('theorder')));

							listTypes = listTypes.set(String(typeData.id), type);
						}

						listTypes = listTypes.sort((a, b) => a.get('theorder') - b.get('theorder'));
						product.set('availableOptionTypes', listTypes);

						//Process variants
						let listVariants = Immutable.OrderedMap<string, ProductVariantStore>();

						Object.values(variants).forEach((variantData:any) => {
							let variant = new ProductVariantStore({
								id: variantData.id,
								slug: variantData.slug,
								name: variantData.name,
							});

							listVariants = listVariants.set(String(variantData.id), variant);
						});

						product.set('variants', listVariants);


						//Process subproducts
						let listSubproducts = Immutable.OrderedMap<string, ProductSubproductStore>();

						Object.values(subproducts).forEach((subproductData:any) => {
							let subproduct = new ProductSubproductStore({
								id: subproductData.id,
								id_product_option_type: subproductData.id_product_option_type,
							});

							listSubproducts = listSubproducts.set(String(subproductData.id), subproduct);
						});

						product.set('subproducts', listSubproducts);

						//Process previews
						let listPreviews = Immutable.OrderedMap<number, PreviewStore>();

						previews.forEach((previewData:any) => {
							let preview = new PreviewStore({
								id: previewData.id,
								slug: previewData.slug,
								theorder: previewData.theorder,
								url: previewData.url,
							});

							listPreviews = listPreviews.set(previewData.id, preview);
						});

						listPreviews = listPreviews.sort((a, b) => a.get('theorder') - b.get('theorder'));
						product.set('previews', listPreviews);

						//Process details
						let listDetails = Immutable.OrderedMap<string, ProductDetailStore>();
						Object.values(details).forEach((detailData:any) => {
							let detail = new ProductDetailStore({
								id: detailData.id,
								slug: detailData.slug,
								name: detailData.name,
							});

							Object.values(detailData.listValues).forEach(( valueData:any) => {
								let { ...rest } =  valueData as IProductDetailValueStore;
								let value = new ProductDetailValueStore();
								value = value.withMutations(value => {
									value.merge(Immutable.fromJS(rest));
								});

								detail = detail.setIn(['listValues', valueData.slug], value);
							});

							listDetails = listDetails.set(detailData.slug, detail);
						});

						product.set('details', listDetails);

					});

					store.setIn(['products', String(product.get('id'))], product);
				}

				//Sort products
				store.set('products', store.get('products').sort((a, b) => {
					return a.get('id_product_subcategory') !== b.get('id_product_subcategory') ?
						a.get('id_product_subcategory') - b.get('id_product_subcategory')
						: a.get('theorder') - b.get('theorder');
				}));

				// Add View Product Attribute Types
				for (const [key, value] of Object.entries(action.data.data.viewProductAttributeTypes)) {
					store.setIn(['viewProductAttributeTypes', String(key)], Immutable.fromJS(value));
				}

				// Add View Product Attributes Values
				for (const [key, value] of Object.entries(action.data.data.viewProductAttributeValues)) {
					let att = value as IViewProductAttributeValueStore;
					store.setIn(['viewProductAttributeValues', String(key)], new ViewProductAttributeValueStore({
						type: att.type,
						slug: att.slug,
						name: att.name,
					}));
				}

				//Add View Categories
				for (const [key, value] of Object.entries(action.data.data.viewCategories)) {
					let {...rest} = value as IViewCategoryStore;
					let viewCategory = new ViewCategoryStore();
					viewCategory = viewCategory.withMutations(viewCategory => {
						viewCategory.merge(Immutable.fromJS(rest));
					});

					store.setIn(['viewCategories', String(viewCategory.get('id'))], viewCategory);
				}

				//Add View Products
				for (const [key, value] of Object.entries(action.data.data.viewProducts)) {
					let {price, listAttributes, ...rest} = value as any;
					let viewProduct = new ViewProductStore();

					viewProduct = viewProduct.withMutations(viewProduct => {
						viewProduct.merge(Immutable.fromJS(rest));
						viewProduct.set('price', new PriceStore(price));
						for (const [key, value] of Object.entries(listAttributes)) {
							viewProduct.setIn(['listAttributes', String(key)], value)
						}
					});

					store.setIn(['viewProducts', String(viewProduct.get('id'))], viewProduct);
				}

				//Add Shipping Types
				for (const value of Object.values(action.data.data.shippingTypes)) {
					let {...rest} = value as ShippingTypePayload;

					const oldData = store.get('shippingTypes').get(String(rest.id));
					let newData = new ShippingTypeStore(rest);

					if(oldData) {
						newData = oldData.mergeDeep(newData);
					}

					store.set('shippingTypes', store.get('shippingTypes').set(String(rest.id), newData));
				}

				//Add Store Integrations
				for (const value of Object.values(action.data.data.storeIntegrations)) {
					let {...rest} = value as StoreIntegrationPayload;

					const oldData = store.get('storeIntegrations').get(rest.slug);
					let newData = new StoreIntegrationStore(rest);

					if(oldData) {
						newData = oldData.mergeDeep(newData);
					}

					store.set('storeIntegrations', store.get('storeIntegrations').set(rest.slug, newData));
				}
			});

		case AppDataActionType.EditProductLabData:
			//TODO: Fix this dumb art print hardcoded condition
			if(action.id === 37) {
				store = store.set('products', store.get('products').set('38', store.get('products').get('38').set('labData', action.data))); // Art Print
				//store = store.set('products', store.get('products').set('39', store.get('products').get('39').set('labData', action.data))); // Canvas
				//store = store.set('products', store.get('products').set('40', store.get('products').get('40').set('labData', action.data))); // Wood Print - Unused
				store = store.set('products', store.get('products').set('41', store.get('products').get('41').set('labData', action.data))); // Poster
				//store = store.set('products', store.get('products').set('76', store.get('products').get('76').set('labData', action.data))); // Acrylic Print - Unused
			} else {
				store = store.set('products', store.get('products').set(String(action.id), store.get('products').get(String(action.id)).set('labData', action.data)));
			}

			break;
	}

	return store;
}

export default AppDataReducer