import { useCallback, useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function BedPillowSleeve(props: ProductModelProps) {
    const option_size = props.options.get('size') ?? 'standard'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_size ? {
            'king': new URL('@resources/3d-models/glb/bed-pillow-sleeve-king.glb', import.meta.url),
            'standard': new URL('@resources/3d-models/glb/bed-pillow-sleeve-standard.glb', import.meta.url),
        }[option_size] : undefined
    }, [option_size])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial('product', elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}