import { useContext, useEffect } from "react"
import { FindObject } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function Blanket(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/blanket.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    const thread_color = props.options.get('thread-color') ?? 'black'

    useEffect(() => {
        if (!elements) return
        elements.forEach(e => e.visible = false)
        let front = FindObject('blanket_front', elements)
        let back = FindObject('blanket_back', elements)
        let back_holder = FindObject('holder_blanket_back_' + thread_color, elements)
        front!.visible = true
        back!.visible = true
        back!.material = back_holder!.material
    }, [elements, thread_color])

    // Apply lab texture
    useEffect(() => {
        if (!elements || !productTexture) return
        let front = FindObject('blanket_front', elements)
        front!.material.map = productTexture
        front!.material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}