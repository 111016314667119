import { Autocomplete, type AutocompleteRenderInputParams, Checkbox, Collapse, FormControlLabel, Grid, TextField } from '@mui/material'
import { useAppSelector } from '../../../Common/_hooks/useAppSelector'
import { type ChangeEvent, type SyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { type UserAddressStore } from '../../../UserData/_stores/UserAddressStore'
import { useTranslation } from 'react-i18next'
import { type E164Number } from 'libphonenumber-js/core'
import PhoneField from '../../../Common/Form/PhoneField'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

interface Props {
  address: UserAddressStore
  onChange?: (value: (address: UserAddressStore) => UserAddressStore) => void
  onValidCheck: (valid: boolean) => void
  save?: boolean
  onChangeSave?: (value: boolean) => void
  disabled?: boolean
}

export default function AddressForm(props: Props) {
  const [t] = useTranslation('account-v2')
  const countries = useAppSelector(state => state.get('appData').get('countries'))

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('name', e.target.value))
  }, [props.onChange])

  const onChangeAddress = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('address', e.target.value))
  }, [props.onChange])

  const onChangeAddress2 = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('address2', e.target.value))
  }, [props.onChange])

  const onChangePostalCode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('postal_code', e.target.value.toUpperCase()))
  }, [props.onChange])

  const onChangeCity = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('city', e.target.value))
  }, [props.onChange])

  const onChangePhone = useCallback((value?: E164Number) => {
    props.onChange && props.onChange(address => address.set('phone', value ?? ''))
  }, [props.onChange])

  const onChangeCountry = useCallback((e: SyntheticEvent<Element, Event>, value: { id: number, label: string } | null) => {
    props.onChange && props.onChange(address => address.set('country', value ? value.id : 0))
  }, [props.onChange])

  const onChangeProvince = useCallback((e: SyntheticEvent<Element, Event>, value: { name: string, label: string } | null) => {
    props.onChange && props.onChange(address => address.set('province', value ? value.name : ''))
  }, [props.onChange])

  const onChangeProvinceText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('province', e.target.value))
  }, [props.onChange])

  const onChangeDefaultShipping = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('default_shipping', e.target.checked))
  }, [props.onChange])

  const onChangeDefaultBilling = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(address => address.set('default_billing', e.target.checked))
  }, [props.onChange])

  const onChangeSave = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    props.onChangeSave && props.onChangeSave(e.target.checked)
  }, [props.onChange])

  // Autoselect country based on geo info
  useEffect(() => {
    if (!props.address.get('country') && window.APP_GEO_COUNTRYCODE) {
      const countryObj = countries.find(country => country.get('alpha2_code') === window.APP_GEO_COUNTRYCODE)
      props.onChange && props.onChange(address => address.set('country', countryObj ? countryObj.get('id') : 0))
    }
  }, [countries, props.onChange])

  const countryOptions = useMemo(() => {
    return countries.sortBy(country => country.get('name')).valueSeq().map(country => {
      return {
        id: country.get('id'),
        label: country.get('name')
      }
    }).toArray()
  }, [countries])

  const countryOptionValue = useMemo(() => {
    const countryObj = countries.get(String(props.address.get('country')))
    if (!countryObj) return null

    return {
      id: countryObj.get('id'),
      label: countryObj.get('name')
    }
  }, [props.address.get('country'), countries])

  const listProvinces = useMemo(() => {
    return countries.get(String(props.address.get('country')))?.get('provinces')
  }, [countries, props.address.get('country')])

  const provinceOptions = useMemo(() => {
    if (!listProvinces || listProvinces.count() === 0) return undefined

    return listProvinces.valueSeq().map(province => {
      return {
        name: province.get('name'),
        label: province.get('name')
      }
    }).toArray()
  }, [listProvinces])

  const provinceOptionValue = useMemo(() => {
    const provinceObj = provinceOptions?.find(option => option.name === props.address.get('province'))
    if (!provinceObj) return null

    return provinceObj
  }, [provinceOptions, props.address.get('province')])

  const checkNotEmpty = useCallback((value: string) => value !== '', [])

  const nameValid = useMemo(() => checkNotEmpty(props.address.get('name')), [props.address.get('name')])
  const addressValid = useMemo(() => checkNotEmpty(props.address.get('address')), [props.address.get('address')])
  const cityValid = useMemo(() => checkNotEmpty(props.address.get('city')), [props.address.get('city')])

  const countryValid = useMemo(() => props.address.get('country') > 0, [props.address.get('country')])

  const phoneValid = useMemo(() => {
    const countryObj = countries.get(String(props.address.get('country')))
    const isInternational = !['CA', 'US'].includes(countryObj?.get('alpha2_code') ?? '')

    return isInternational
      ? checkNotEmpty(props.address.get('phone')) && isPossiblePhoneNumber(props.address.get('phone'))
      : props.address.get('phone') === '' || isPossiblePhoneNumber(props.address.get('phone'))
  }, [props.address.get('country'), props.address.get('phone')])

  const provinceValid = useMemo(() => {
    // If we don't have a list of provinces, just check that it's not empty
    if (!listProvinces || listProvinces.count() === 0) { return checkNotEmpty(props.address.get('province')) }

    // Confirm it's a valid province otherwise
    return !!listProvinces.find(province => province.get('name') === props.address.get('province'))
  }, [props.address.get('province'), listProvinces])

  const postalCodeValid = useMemo(() => {
    const countryObj = countries.get(String(props.address.get('country')))
    let valid = true
    if (countryObj) {
      if (countryObj.get('name') === 'Canada') {
        valid = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(props.address.get('postal_code'))
      } else if (countryObj.get('name') === 'United States') {
        valid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(props.address.get('postal_code'))
      }
    }
    return valid
  }, [props.address.get('postal_code'), countries, props.address.get('country')])

  const addressFormValid = useMemo(() => {
    return nameValid && addressValid && cityValid && provinceValid && countryValid && phoneValid && postalCodeValid
  }, [nameValid, addressValid, cityValid, provinceValid, countryValid, phoneValid, postalCodeValid])

  useEffect(() => {
    props.onValidCheck(addressFormValid)
  }, [addressFormValid])

  const renderCountryInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextField
      label={t('Country')}
      error={!countryValid}
      {...params}
    />
  }, [t, countryValid])

  const renderProvinceInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextField
      label={t('Province / State')}
      error={!provinceValid}
      {...params}
    />
  }, [t, provinceValid])

  return <Grid
    container
    spacing={2}
  >
    <Grid item xs={12} sm={6}>
      <TextField
        label={t('Full name')}
        value={props.address.get('name')}
        error={!nameValid}
        onChange={onChangeName}
        autoFocus
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label={t('Address Line 1')}
        value={props.address.get('address')}
        error={!addressValid}
        onChange={onChangeAddress}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label={t('Address Line 2')}
        value={props.address.get('address2')}
        onChange={onChangeAddress2}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <PhoneField
        label={t('Phone number')}
        defaultCountry={props.address.get('country')}
        value={props.address.get('phone')}
        onChange={onChangePhone}
        error={!phoneValid}
        helperText={props.address.get('phone') !== '' && !phoneValid ? t('Please enter a valid phone number') : ''}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label={t('City')}
        value={props.address.get('city')}
        error={!cityValid}
        onChange={onChangeCity}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label={t('Postal code')}
        value={props.address.get('postal_code')}
        error={!postalCodeValid}
        helperText={props.address.get('postal_code') !== '' && !postalCodeValid ? t('Please enter a valid postal code') : ''}
        onChange={onChangePostalCode}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Autocomplete
        options={countryOptions}
        value={countryOptionValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={onChangeCountry}
        autoComplete
        autoHighlight
        disabled={props.disabled}
        renderInput={renderCountryInput}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      {provinceOptions ? <Autocomplete
        options={provinceOptions}
        value={provinceOptionValue}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={onChangeProvince}
        autoComplete
        autoHighlight
        disabled={props.disabled}
        renderInput={renderProvinceInput}
      /> : <TextField
        label={t('Province / State')}
        value={props.address.get('province')}
        error={!provinceValid}
        onChange={onChangeProvinceText}
        disabled={props.disabled}
      />}
    </Grid>
    <Grid item xs={12}>
      { props.onChangeSave ? <>
        <FormControlLabel
          control={<Checkbox
            checked={props.save}
            onChange={onChangeSave}
          />}
          label={t('Save address in my account')}
          disabled={props.disabled}
        /><br />
      </> : null }
      <Collapse in={props.save}>
        <FormControlLabel
          control={<Checkbox
            checked={props.address.get('default_shipping')}
            onChange={onChangeDefaultShipping}
          />}
          label={t('Set as default shipping address')}
          disabled={props.disabled}
        />
        <br/>
        <FormControlLabel
          control={<Checkbox
            checked={props.address.get('default_billing')}
            onChange={onChangeDefaultBilling}
          />}
          label={t('Set as default billing address')}
          disabled={props.disabled}
        />
      </Collapse>
    </Grid>
  </Grid>
}
