import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide } from "three"

export default function CheekyBriefs(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/cheeky-briefs.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        
        let product_mat = FindMaterial('Peachskin', elements)
        if(product_mat){
            product_mat.map = productTexture
            product_mat.map.flipY = false
            product_mat.map.needsUpdate = true
            product_mat.side = DoubleSide
        }
        
        let elastic_mat = FindMaterial('Elastic', elements)
        if(elastic_mat){
            elastic_mat.map = productTexture
            elastic_mat.map.flipY = false
            elastic_mat.map.needsUpdate = true
        }
        
        let inside_mat = FindMaterial('Peachskin_in', elements)
        if(inside_mat){
            inside_mat.transparent = true
            inside_mat.opacity = 0.5
        }
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}