import * as Immutable from 'immutable';

export interface IProductSubproductStore {
	id: number
  id_product_option_type: number
}

const defaultProductSubproductStore = Immutable.Record<IProductSubproductStore>({
	id: 0,
  id_product_option_type: 0,
});

export default class ProductSubproductStore extends defaultProductSubproductStore implements IProductSubproductStore {}