import * as Immutable from 'immutable';
import { DATA_VERSION } from './config';
import UserDataStore from './UserData/_stores/UserDataStore';
import UIDataStore from './UIData/_stores/UIDataStore';
import AppDataStore from './AppData/_stores/AppDataStore';
import AuthStore from './Auth/_stores/AuthStore';
import DashboardStore from './Dashboard/_stores/DashboardStore';
import ViewProductStore from './AppData/_stores/ViewProductStore';
import PriceStore from './UIData/_stores/PriceStore';
import { Action, configureStore } from '@reduxjs/toolkit';
import AjaxMiddleware from './Common/_middlewares/AjaxMiddleware';
import WebsiteReducer from './WebsiteReducer';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

// TODO: Fix types for interface entries across all stores to avoid using Immutable types as for most usages they're not converted yet

export interface IWebsiteStore {
	userData: UserDataStore
	UIData: UIDataStore
	Auth: AuthStore
	Dashboard: DashboardStore
	appData: AppDataStore
	version: string
}

export const WebsiteStoreFactory = Immutable.Record<IWebsiteStore>({
	userData: new UserDataStore,
	UIData: new UIDataStore,
	Auth: new AuthStore,
	Dashboard: new DashboardStore,
	appData: new AppDataStore,
	version: DATA_VERSION,
});

export default class WebsiteStore extends WebsiteStoreFactory implements IWebsiteStore {
	constructor(storeObject:object = {}) {
		let store = Immutable.Map({
			UIData: new UIDataStore,
			userData: new UserDataStore,
			appData: new AppDataStore,
			Auth: new AuthStore,
			version: DATA_VERSION
		});

		store = Immutable.mergeDeep(store, Immutable.fromJS(storeObject, (key, value, path) => {
			if(path[0] === "appData") {
				if(path[1] === 'viewProducts') {
					if(key === 'price') {
						return new PriceStore(value.toJS() as any);
					}
				}

				if(path[1] === 'products') {
					if(['price_retail','price_wholesale','price_dropship'].includes(String(key))) {
						return new PriceStore(value.toJS() as any);
					}
				}

				if(path[1] === 'brandingOptionTypes') {
					if(key === 'price') {
						return new PriceStore(value.toJS() as any);
					}
				}
			}
			return Immutable.isKeyed(value) ? value.toOrderedMap() : value.toList();
		}));

		super(store);
	}
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const isBrowser = typeof document !== 'undefined';
const initialStore = isBrowser ? new WebsiteStore(window?.PRELOADED_STORE || {}) : new WebsiteStore({});

export const store = configureStore<WebsiteStore, Action>({
	reducer: WebsiteReducer,
	preloadedState: initialStore,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: false,
		actionCreatorCheck: true,
		serializableCheck: false,
		thunk: false,
	}).concat(AjaxMiddleware),
	enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
	devTools: {
		//@ts-ignore
		stateSanitizer: (state:WebsiteStore) => state.withMutations(state => {
			//Remove some data from the redux devtools so it's actually usable
			state.setIn(['appData', 'countries'], state.get('appData').get('countries').take(1));
			state.setIn(['appData', 'products'], state.get('appData').get('products').filter((p,k) => k == '39' || k == "38"));
			state.setIn(['appData', 'productCategories'], state.get('appData').get('productCategories').take(1));
			state.setIn(['appData', 'productSubCategories'], state.get('appData').get('productSubCategories').take(1));
			state.setIn(['UIData', 'designLab','patternDesignService','categories'], '%CATEGORIES%');
			state.setIn(['UIData', 'designLab','patternDesignService','patterns'], '%PATTERNS%');
			state.setIn(['appData', 'viewCategories'], state.get('appData').get('viewCategories').filter((p,k) => k == '1'));
			state.setIn(['appData', 'viewProducts'], state.get('appData').get('viewProducts').filter((p,k) => k == '9'));
			state.setIn(['appData', 'viewProductAttributeValues'], state.get('appData').get('viewProductAttributeValues').filter((p,k) => k == '1'));
			state.setIn(['userData', 'productDesigns','data'], state.get('userData').get('productDesigns').get('data').take(1));
		})
	}
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store