import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { Avatar, IconButton, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Tooltip, styled } from '@mui/material';
import { ReactSVG } from 'react-svg';
import useLabData from '../_hooks/useLabData';
import { EditActiveOption } from '../../UIData/_actions/DesignLabStoreActions';
import { Color } from 'three';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import Immutable from 'immutable';
import LabOptionTool, { LabOptionValue } from './LabOptionTool';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const OPTION_IDS: Record<string, string> = {
  'color': '3',
  'fabric': '2',
  'zipper-color': '27',
  'zipper-pull': '28',
  'loop-color': '37',
  'canvas-frame': '38',
};

type Props = {
  option: string,
}

export default function OptionTool(props: Props) {
  const dispatch = useAppDispatch();
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
  const optionType = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId))?.get('availableOptionTypes').get(OPTION_IDS[props.option]));
  const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'));
  const labData = useLabData();
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo(() => {
    return activeOptions.get(props.option);
  }, [props.option, activeOptions])

  useEffect(() => {
    //This is used to select a default value, so stop here if we have one
    if(value) return;

    const optionType = productData?.get('availableOptionTypes').find(optionType => optionType.get('slug') === props.option);
    if(!optionType) return;

    let option = optionType.get('options').first();

    //Try to get from searchparams
    const paramOption = searchParams.get('option_'+optionType.get('slug'))

    if(!!paramOption) {
        option = optionType.get('options').find(option => option.get('slug') === paramOption) ?? option
    }

    if(!option) return

    dispatch(EditActiveOption(optionType.get('slug'), option.get('slug')));
  }, [props.option, productData, value, searchParams]);

  const listValues = useMemo(() => {
    let tmpListValues = Immutable.OrderedMap<string, LabOptionValue>()

    if (!labData || !optionType) return tmpListValues;

    const product = window.Products.getProduct(labData.id);

    optionType?.get('options').forEach(option => {
      const threeColor = product.getColor(option.get('slug')) || new Color(0x000000)

      tmpListValues = tmpListValues.set(option.get('slug'), new LabOptionValue({
        name: option.get('name'),
        slug: option.get('slug'),
        color: threeColor ? '#' + threeColor.getHexString() : undefined,
      }))
    })

    return tmpListValues
  }, [optionType, labData]);

  if (!optionType) return null

  return <LabOptionTool
    type="option"
    layout={optionType.get('slug') === 'fabric' ? 'img-badge' : 'color'}
    name={optionType.get('name')}
    option={props.option}
    listValues={listValues}
  />
}